.intro {
  z-index: 999;
  width: 100%;
  height: 100dvh;
  position: fixed;
  overflow: hidden;
  transition: 0.65s ease-in both;

  @media (max-width: 600px) {
    background-color: black;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 600px) {
      object-fit: contain;
    }
  }
}

.swirl_out {
  animation: swirl-out 0.65s ease-in both;
}

@keyframes swirl-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
