@font-face {
  font-family: MersadRegular;
  src: url("components/Mersad Regular.otf");
}

.section {
  width: 100%;
  height: 101dvh;
  z-index: 10;
  background-color: #121212;
  overflow: hidden;
}

.contactBox {
  width: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  @media (max-width: 900px) {
    width: 95%;
  }

  .contentBox {
    background-color: #1e1e1e;
    border-radius: 5px;

    .cancelRow {
      display: flex;
      width: 95%;
      justify-content: end;
      margin: 0 auto;
      padding: 1rem 0 1rem 0;

      div {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: rgba(white, 0.5);
        cursor: pointer;
      }

      .cancel {
        position: absolute;
        top: 50%;
        left: 14%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 1.2px;
        background-color: black;
        border-radius: 4px;
        transition: 0.5s;

        &:nth-child(1) {
          transform: rotate(-135deg);
        }

        &:nth-child(2) {
          transform: rotate(135deg);
        }
      }
    }

    .header {
      width: 90%;
      margin: 0 auto;
      text-align: start;
      font-size: clamp(22px, 4vw, 40px);
      font-family: "Inter";
      font-weight: 500;
      margin-bottom: 3rem;
      margin-top: 2rem;

      @media (max-width: 560px) {
        transform: translateY(40%);
        margin-top: 10rem;

        span {
          display: block;
          width: 13rem;
        }
      }
    }

    .content {
      display: flex;
      width: 90%;
      margin: 0 auto;
      font-family: "Inter";
      margin-bottom: 2rem;

      @media (max-width: 560px) {
        margin-bottom: 0;
      }

      .buttonChoice {
        width: 50%;
        margin-top: 1rem;

        @media (max-width: 560px) {
          width: 80%;
        }

        .checkButton {
          width: 100%;
          margin-bottom: 2rem;

          input[type="radio"] {
            display: none;
          }

          .checkbox_custom {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1px solid white;
            border-radius: 50%;
            position: relative;
            margin-right: 10px;
            cursor: pointer;
          }

          .checkbox_custom::before {
            content: "";
            width: 16px;
            height: 16px;
            background-color: yellow;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease;
          }

          input[type="radio"]:checked + .checkbox_custom::before {
            transform: translate(-50%, -50%) scale(1);
          }
          label {
            cursor: pointer;
          }

          label:last-of-type {
            margin-left: 1rem;
            font-size: clamp(14px, 2vw, 16px);
          }

          &:last-of-type {
            display: flex;
            right: -30%;
            top: -20%;

            @media (max-width: 1100px) {
              right: -35%;
            }

            @media (max-width: 950px) {
              right: -40%;
            }

            input {
              position: absolute;
              width: 15rem;
              height: 30px;

              @media (max-width: 1100px) {
                width: 11rem;
              }
            }
          }
        }
      }

      .descritption {
        width: 50%;
        font-family: "Inter";
        font-weight: 200;
        opacity: 0.75;

        @media (max-width: 560px) {
          position: absolute;
          width: 100%;
          top: -70%;
        }

        span {
          width: 78%;
          display: block;
          font-size: clamp(16px, 3vw, 20px);
          padding: 2rem 0 0 3rem;

          @media (max-width: 1050px) {
            width: 98%;
          }

          @media (max-width: 560px) {
            padding: 0;
          }
        }

        img {
          position: absolute;
          left: 55%;
          top: -5%;
          transform: translateX(-50%);
          object-fit: contain;

          @media (max-width: 560px) {
            left: 50%;
            top: 40%;
            transform: translate(-50%, -30%) scale(0.8);
          }
        }

        .dollar {
          @media (max-width: 560px) {
            transform: translate(-50%, -45%) scale(0.7) rotate(50deg);
          }
        }

        .clock {
          @media (max-width: 560px) {
            transform: translate(-50%, -45%) scale(0.7) rotate(-10deg);
          }
        }

        &.notFirst {
          transform: translateY(-30%);
        }
      }
    }

    .footer {
      width: 95%;
      margin: 0 auto;
      padding-bottom: 2rem;
      display: flex;
      justify-content: end;

      .footerContent {
        background-color: #232323;
        padding: 0 0.7rem 0 0.7rem;

        .pageCounter {
          display: flex;
          align-items: center;
          label {
            font-size: 12px;
            margin-right: 0.5rem;
            font-family: "Inter";
            opacity: 0.75;
          }

          span {
            font-size: 24px;
            padding-top: 0.5rem;
            font-family: MersadRegular;
            opacity: 0.8;
          }
        }
      }
    }

    .pagination {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      button {
        background-color: #ffffff;
        width: 28px;
        height: 28px;
        color: rgb(0, 0, 0);
        border: none;
        border-radius: 50%;
        margin: 0 10px;
        cursor: pointer;
        font-size: 25px;

        span {
          position: absolute;
          top: 39%;
          transform: translate(-50%, -50%);
        }
      }

      .buttonPrev img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-60%, -45%) scale(0.5);
      }
      .buttonNext img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -30%) scale(0.5);
      }
    }

    &.hidden {
      transform: translateX(-150%);
    }
  }
}

.sideMail {
  position: absolute;
  width: 60%;
  margin: 0 auto;
  transition: 1s;
  left: 50%;
  top: 50%;
  transform: translate(150%, -50%);

  @media (max-width: 560px) {
    width: 95%;
  }

  .socialCard {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #1e1e1e;

    .header {
      width: 80%;
      margin: 0 auto;
      color: white;
      text-align: start;
      font-size: 26px;
      font-family: "Inter";
      font-weight: 500;
      text-transform: uppercase;

      margin-bottom: 3rem;
      margin-top: 4rem;
    }

    .fieldBox {
      width: 80%;
      height: 80%;
      margin: 2rem auto 6rem auto;
      display: flex;
      flex-wrap: wrap;
      gap: 0.6rem;
      

      // :nth-child(4),
      // :nth-child(3),
      :nth-child(5) {
        width: 100% !important;
      }

      // .fieldElem:nth-child(1) {
      //   margin-right: 2%;
      // }

      .fieldElem {
        width: 49%;
        // margin-bottom: 0.2rem;
        // margin-left: 0.4rem;

        @media (max-width: 1000px) {
          width: 100%;
        }

        .inputField {
          min-width: 100%;
          max-width: 100%;
          min-height: 2.6rem;
          max-height: 120px;
          color: rgba(255, 255, 255, 0.7);
          background-color: #1e1e1e;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 15px;
          padding-left: 15px;
          font-size: 15px;
          font-family: "Inter";
        }

        .textArea {
          min-width: 100%;
          max-width: 100%;
          min-height: 4.6rem;
          max-height: 120px;
          padding: 10px 2.4rem 0 10px;
          color: rgba(255, 255, 255, 0.7);
          background-color: #1e1e1e;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 18px;
          padding-left: 15px;
          font-size: 15px;
          margin-bottom: 3rem;
          font-family: "Inter";
          resize: vertical;

          &:focus {
            border: 1px solid rgba(255, 255, 255, 0.6);
          }
        }
      }

      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        color: white;
        padding: 0.5rem;
        border-radius: 12px;
        box-shadow: 0 0 0 1px white;
        font-family: "Inter";
        cursor: pointer;
        margin-bottom: 2rem;

        &:hover {
          background-color: rgba(#ffffff, 0.1);
          box-shadow: 0 0 0 2px white;
        }
      }

      .selectedFiles {
        display: flex;
        position: absolute;
        transform: translateY(-140%);
        width: 100%;
        color: rgba(255, 255, 255, 0.75);

        .fileName {
          width: 20%;
          display: flex;
          margin-right: 0.5rem;
          border-radius: 15px;
          box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.75);
          background-color: #1e1e1e;

          label {
            width: 90%;
            padding: 0.3rem 0.3rem 0.3rem 0.8rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            font-family: "Inter";
          }

          b {
            display: flex;
            align-items: center;
            color: black;
            background-color: red;
            cursor: pointer;
          }
        }

        .cancelButton {
          position: absolute;
          right: 20%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          .cancel {
            position: absolute;
            top: 50%;
            left: 14%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 1.2px;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;
            transition: 0.5s;

            &:nth-child(1) {
              transform: rotate(-135deg);
            }

            &:nth-child(2) {
              transform: rotate(135deg);
            }
          }
        }
      }
    }

    .cancelRow {
      position: absolute;
      top: 0;
      display: flex;
      width: 98%;
      justify-content: end;
      margin: 0 auto;
      padding: 1rem 0 1rem 0;

      div {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: rgba(white, 0.5);
        cursor: pointer;
      }

      .cancel {
        position: absolute;
        top: 50%;
        left: 14%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 1.2px;
        background-color: black;
        border-radius: 4px;
        transition: 0.5s;

        &:nth-child(1) {
          transform: rotate(-135deg);
        }

        &:nth-child(2) {
          transform: rotate(135deg);
        }
      }
    }

    .pagination {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      .prevLayout {
        width: 120px;
        border-radius: 100px;
        background-color: #232323;
        cursor: pointer;
        padding: 0.5rem;

        label {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 20px;
          font-family: "Inter";
          cursor: pointer;
        }
        button {
          background-color: #ffffff;
          width: 28px;
          height: 28px;
          color: rgb(0, 0, 0);
          border: none;
          border-radius: 50%;
          font-size: 25px;
          cursor: pointer;

          span {
            position: absolute;
            top: 39%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .buttonPrev img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-60%, -45%) scale(0.5);
      }
      .buttonNext img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
      }
    }

    .loaded {
      width: 100%;
      margin: 0 auto;

      img {
        width: 100%;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(26deg)
          brightness(200%) contrast(100%);
        scale: 0.5;
      }

      p {
        position: absolute;
        width: 40%;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: clamp(10px, 2vw, 18px);
        color: white;
        font-family: "Inter";
      }
    }
  }

  &.active {
    transform: translate(-50%, -50%);
  }
}

.banner {
color: #ffffff;
padding-bottom: 0.5rem;
font-size: 16px;
}

.loaded {
  height: auto;
  opacity: 1;
  // overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.loaded.active {
  height: auto; /* или фиксированное значение */
  opacity: 1;
}
