@mixin noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// .main {
//   height: 100%;
//   z-index: 10;
//   background-color: #f0f0f0;
// }

.layout {
  @include noselect();
  width: 100%;
  max-width: 2200px;
  margin: 0 auto;
  z-index: 12;
  transition: 0.4s;
  background-color: #121212;

  .backBut {
    position: fixed;
    bottom: 2%;
    right: 3%;
    width: 80px;
    height: 80px;
    transform: rotate(-180deg);
    background-color: #ffffff;
    border-radius: 50%;
    z-index: 7;
    cursor: pointer;
    background-image: url("../assets/img/arrowInWhite.svg");
    background-repeat: no-repeat;
    background-position: center;
    // filter: invert(1);

    @media (max-width: 820px) {
      bottom: 3%;
      right: 4%;
      width: 60px;
      height: 60px;
    }
  }

  .Headerlayout {
    @include noselect();
    position: fixed;
    width: 100%;
    max-width: 2200px;
    margin: 0 auto;
    z-index: 12;
    transition: 0.4s;
    background-color: transparent;
    //   text-wrap: nowrap;
    //   overflow: hidden;
    color: white;
    text-transform: uppercase;

    &.active {
      .headerContent .buttonSide .h_but_s {
        opacity: 1;
      }
    }

    .headerContent {
      display: flex;
      width: 100%;
      position: fixed;
      margin: 0 auto;
      z-index: 999;

      .menuButton {
        position: absolute;
        right: 2%;
        width: 50px;
        height: 50px;
        z-index: -1;
        opacity: 0;
        transition: 0.5s;

        .toggle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          height: 3px;
          background-color: rgb(0, 0, 0);
          border-radius: 4px;
          transition: 0.5s;
          margin-top: 0.5rem;

          &:nth-child(1) {
            top: 35%;
          }

          &:nth-child(2) {
            top: 50%;
          }

          &:nth-child(3) {
            width: 15px;
            left: calc(50% - 5px);
            top: 65%;
          }
        }

        &.visible {
          opacity: 1;
          z-index: 999;
        }

        &.active {
          transform: translateX(-25%);

          .toggle {
            &:nth-child(1) {
              top: 50%;
              transform: rotate(135deg);
            }

            &:nth-child(2) {
              opacity: 0;
              transform: translate(0, 0) rotate(0);
            }

            &:nth-child(3) {
              top: 50%;
              left: 50%;
              width: 25px;
              transform: rotate(-135deg);
            }
          }
        }
      }

      .titleSide {
        flex: 1 1;
        margin-top: 0.5rem;
        padding: 0.6rem 0.7rem 0.5rem 1rem;
        font-size: 16px;
        font-family: "Inter";
        font-weight: 800;
        color: #f0f0f0;
        z-index: 99;

        span {
          cursor: pointer;
        }

        @media (max-width: 690px) {
          position: absolute;
          padding: 1rem 0.7rem 0.5rem 1rem;
        }
      }

      .h_but_s_Mobile {
        position: absolute;
        right: 1rem;
        margin-top: 1rem;
        padding: 0.6rem 0.7rem 0.5rem 1rem;
        text-decoration: none;
        font-family: "Inter";
        background-color: #d3e400;
        color: #000000;
        font-weight: 500;
        border-radius: 6px;
        font-size: 12px;
      }

      .buttonSide {
        margin-top: 0.5rem;

        &.mobile {
          width: 100%;
          height: 0;
          border-radius: 0 0 15px 15px;
          top: 23.5%;
          display: flex;
          justify-content: center;
          box-shadow: none;
          transition: 1s;
          margin-top: 0;
          overflow: hidden;

          ul {
            width: 90%;
            margin-top: 0 !important;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            height: max-content;
            transition: 1s;

            li {
              width: 100%;
            }

            .h_but_s {
              font-family: "Inter";
              background-color: transparent;
              color: #121212;
              font-weight: 800;
              border-radius: 0;
              font-size: 12px;
              opacity: 1;
              border-bottom: 1px solid black;

              &:last-child {
                background-color: transparent;
                color: #121212;
                opacity: 1;
                font-weight: 800;
              }
            }

            .underline {
              width: 65%;
              height: 20%;
            }
          }

          &.active {
            backdrop-filter: blur(50px);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            height: 30rem;
            box-shadow: 0 4px 7px 1px rgb(0, 0, 0);

            ul {
              margin-top: 10rem !important;
              opacity: 1;
            }
          }
        }

        ul {
          list-style: none;
          display: flex;
          justify-content: end;
          gap: 0.4rem;
          padding-right: 1rem;
          padding-bottom: 0.7rem;
          color: white;

          .h_but_s {
            font-size: 12px;
            padding: 0.6rem 1rem;
            background-color: #232323;
            border-radius: 6px;
            transition: 0.2s;
            cursor: pointer;
            opacity: 0;
            font-family: "Inter";
            text-decoration: none;

            &:last-child {
              background-color: #d3e400;
              color: #121212;
              cursor: pointer;
              opacity: 1;
              font-weight: 500;
            }

            &:hover {
              box-shadow: 0 0 0 1px white;
            }
          }
        }
      }
    }
  }
}

.contactBlock {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid rgb(255, 255, 255);
  border-radius: 15px;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::after {
    top: auto;
    bottom: 5%;
    left: 0;
    width: 1px;
    height: 95%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(0, 0, 0, 0.4)
    );
  }

  &::before {
    left: auto;
    bottom: 5%;
    right: 0;
    width: 1px;
    height: 95%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(0, 0, 0, 0.4)
    );
  }

  .container {
    position: relative;

    &__contact {
      padding-top: 0.5em;

      h1 {
        width: 90%;
        margin: 3rem auto 0 auto;
        font-family: "Inter";
        font-weight: 500;
        color: rgb(255, 255, 255);
        font-size: 32px;
      }
    }
  }

  .cardGridMobile {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2.5em;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::after {
      top: auto;
      bottom: 1.5%;
      left: 0;
      width: 1px;
      height: 95%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8),
        rgba(0, 0, 0, 0.2)
      );
    }

    &::before {
      left: auto;
      bottom: 1.5%;
      right: 0;
      width: 1px;
      height: 95%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8),
        rgba(0, 0, 0, 0.2)
      );
    }

    .socialContainerMobile {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 0.2em 0.2em 0 0.2em;
      width: 100%;
      height: 950px;

      @media (max-width: 660px) {
        height: 800px;
      }

      @media (max-width: 540px) {
        height: 700px;
      }

      @media (max-width: 440px) {
        height: 650px;
      }

      :nth-child(5) {
        width: 100% !important;
      }

      .socialCard {
        position: relative;
        width: 49%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        margin: 0.5%;
        border-radius: 35px;
        transition: 0.15s;
        transition: background-color 0.6s;

        img,
        .title {
          transform: scale(0.8);
          transition: transform 0.7s;

          @media (max-width: 660px) {
            transform: scale(0.7);
          }

          @media (max-width: 440px) {
            transform: scale(0.6);
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;

          img {
            transform: scale(1);
            filter: invert(1);
          }

          .title {
            transform: translate(-50%, -100%);
            color: rgb(0, 0, 0);

            @media (max-width: 660px) {
              transform: translate(-50%, -100%);
            }

            @media (max-width: 440px) {
              transform: translate(-50%, -100%);
            }
          }
        }

        .image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .title {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
          font-size: clamp(10px, 2vw, 20px);
          font-weight: 800;
          bottom: 10%;
          color: #f0f0f0;
          font-family: "Inter";
        }
      }
    }
  }

  .cardGrid {
    width: 100%;
    margin: 0 auto;
    margin-top: 7em;
    padding-bottom: 2.5em;

    @media (max-width: 1000px) {
      margin-top: 0;
    }

    .socialContainer {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      max-width: 75%;
      margin: 0 auto;
      gap: 10px;
      margin-bottom: 10rem;

      .socialCard {
        position: relative;
        width: 240px;
        height: 240px;
        border-radius: 30%;
        transition: 0.15s;
        transition: background-color 0.6s;

        img {
          transform: scale(0.8);
          transition: transform 0.7s;
        }

        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;

          img {
            transform: scale(1);
            filter: invert(1);
          }
        }

        .image {
          position: absolute;
          margin-top: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      .title {
        display: block;
        text-align: center;
        color: #f0f0f0;
        font-size: 15px;
        margin-top: 2rem;
        text-transform: uppercase;
        font-family: MersadExtraBold;
      }
    }
  }

  .sideMail {
    width: 99%;
    margin: 0 auto;

    .socialCard {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: #1e1e1e;

      .mailBlank {
        color: #f0f0f0;
        width: 60%;
        margin-left: 5%;

        .mailTile {
          text-transform: uppercase;
          font-size: 25px;
          margin: 4rem 0 1rem 0;
          font-family: "Inter";
          font-weight: 500;
        }

        .mailInf {
          font-size: 14px;
          margin-bottom: 5rem;
          font-family: "Inter";
        }

        .title {
          width: 60%;
          text-transform: uppercase;
          font-size: 21px;
          margin: 2rem 0 5rem 0;
          font-family: "Inter";
        }
      }

      .fieldBox {
        width: 40%;
        height: 80%;
        margin: 2rem 5rem 0 auto;
        display: flex;
        flex-wrap: wrap;

        :nth-child(5) {
          width: 100% !important;
        }

        .fieldElem:nth-child(1),
        .fieldElem:nth-child(3) {
          margin-right: 2%;
        }

        .fieldElem {
          width: 49%;
          margin-top: 0.5rem;

          input {
            background-color: #1e1e1e;
            border: 1px solid hsla(0, 0%, 94%, 0.4);
          }

          .image {
            position: absolute;
            top: 0;
            right: 0%;
            padding: 0.2rem;
            border-radius: 8px;
            cursor: pointer;
            transform: translate(-20%, 20%);
            width: 33px;
            height: 30px;
            background-color: #313131;

            img {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .textArea {
            min-width: 100%;
            max-width: 100%;
            min-height: 2.6rem;
            max-height: 120px;
            padding: 10px 2.4rem 0 10px;
            background-color: #1e1e1e;
            border: 1px solid hsla(0, 0%, 94%, 0.4);
            border-radius: 15px;
            padding-left: 15px;
            font-size: 15px;
            margin-bottom: 3rem;
            font-family: "Inter";
            resize: vertical;

            &:focus {
              border: 1px solid rgba(255, 255, 255, 0.6);
            }
          }
        }

        .button {
          width: 100%;
          display: flex;
          justify-content: center;
          color: #f0f0f0;
          border: 1px solid hsla(0, 0%, 94%, 0.4);
          padding: 0.5rem;
          border-radius: 12px;
          box-shadow: 0 0 0 1px #f0f0f0;
          font-family: "Inter";
          cursor: pointer;
          margin-bottom: 2rem;

          &:hover {
            background-color: rgba(#000000, 0.1);
            box-shadow: 0 0 0 2px rgb(0, 0, 0);
          }
        }

        .selectedFiles {
          display: flex;
          position: absolute;
          transform: translateY(-140%);
          width: 100%;
          color: rgba(0, 0, 0, 0.75);

          .fileName {
            width: 20%;
            display: flex;
            margin-right: 0.5rem;
            border-radius: 15px;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.75);
            background-color: #f0f0f0;

            label {
              width: 90%;
              padding: 0.3rem 0.3rem 0.3rem 0.8rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 14px;
              font-family: "Inter";
            }
          }

          .cancelButton {
            position: absolute;
            right: 20%;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            .cancel {
              position: absolute;
              top: 50%;
              left: 14%;
              transform: translate(-50%, -50%);
              width: 16px;
              height: 1.2px;
              background-color: rgb(0, 0, 0);
              border-radius: 4px;
              transition: 0.5s;

              &:nth-child(1) {
                transform: rotate(-135deg);
              }

              &:nth-child(2) {
                transform: rotate(135deg);
              }
            }
          }
        }
      }
    }
  }
}

.headerSection {
  width: 98%;
  margin: 1rem auto 8rem 1rem;
  padding-top: 3.5rem;

  .row {
    width: 95%;

    label {
      font-size: clamp(70px, 12vw, 180px);
      font-family: "Inter";
      color: #f0f0f0;
    }
  }

  .image {
    display: flex;
    width: 100%;

    img {
      width: clamp(170px, 70vw, 60rem);
      height: calc(100% * (9 / 16));
      border-radius: 25px;
      margin-left: auto;
      object-fit: cover;
    }

    .arrow {
      position: absolute;
      bottom: -9%;
      right: 3%;
      transform: translate(-110%, -105%);
      width: 40px;
      height: 40px;
      z-index: 7;
      cursor: pointer;

      @media (max-width: 820px) {
        scale: 0.9;
        right: 3.5%;
        bottom: -13%;
      }

      @media (max-width: 710px) {
        scale: 0.8;
        right: 4.5%;
        bottom: -14%;
      }

      @media (max-width: 600px) {
        scale: 0.7;
        right: 5.5%;
        bottom: -16%;
        right: 2.4%;
      }

      @media (max-width: 515px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      width: 95%;
    }
  }

  .span {
    font-family: "Inter";
    text-transform: uppercase;
    font-weight: 300;
    font-size: clamp(20px, 4vw, 32px);
    width: 90%;
    text-indent: calc(111% - clamp(170px, 70vw, 70rem));
    color: #f0f0f0;
    margin-top: 30px;
    line-height: 140%;
  }
}

.descriptionSection {
  width: 95%;
  margin: 6rem 0 0 0;
  padding-left: 1rem;
  color: #f0f0f0;

  // label {
  //   font-size: clamp(42px, 10vw, 92px);
  //   font-family: "Inter";
  // }
  // span {
  //   font-size: clamp(35px, 6vw, 50px);
  //   font-family: "Inter";
  // }

  .details {
    display: flex;
    // justify-content: space-around;
    font-size: clamp(16px, 2vw, 20px);
    color: #bbbbbb;
    font-family: "Inter";
    margin-top: 6rem;
    margin-bottom: 8rem;

    article {
      font-size: clamp(32px, 8vw, 48px);
      color: #f0f0f0;
    }

    @media (max-width: 830px) {
      flex-direction: column;
      gap: 3rem;
      line-height: 130%;
      margin-top: 3rem;
      font-size: clamp(18px, 2vw, 22px);

      article {
        font-size: clamp(32px, 8vw, 64px);
      }
    }

    .listFlex {
      width: 60%;
      display: flex;
      gap: 8rem;
      margin-left: clamp(6rem, 12vw, 20rem);

      @media (max-width: 830px) {
        width: 100%;
        flex-direction: column;
        gap: 2rem;
        margin-left: 0;
      }
    }

    article {
      // width: 100%;
      text-align: left;

      @media (max-width: 830px) {
        text-align: start;
      }
    }

    p {
      text-transform: none;
      // width: 30%;

      @media (max-width: 830px) {
        width: 100%;
      }
    }
  }

  .gifRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 750px) {
      flex-direction: column;

      :first-of-type {
        width: 70% !important;
      }

      :last-of-type {
        width: 70% !important;
        margin-left: auto;
      }
    }

    img {
      object-fit: none;
    }

    :first-of-type {
      width: calc(50% - 10px);
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }

    :last-of-type {
      width: 50%;
      height: 20%;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  // .fullVideo {
  //   margin-top: 10rem;
  // }
}

.headerVideo {
  margin: 2rem auto 0 auto;
  height: 80dvh;

  @media (max-width: 900px) {
    height: 600px;
  }

  @media (max-width: 700px) {
    height: 500px;
  }

  @media (max-width: 500px) {
    height: 400px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
  }

  .playButton {
    position: absolute;
    transition: 0.5s;
    width: 210px;
    height: 210px;
    background-color: rgba(#fff, 0.05);
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 0.5px white;
    filter: blur(0.5px);
    backdrop-filter: blur(15px);
    opacity: 1;

    @media (max-width: 900px) {
      transform: translate(-50%, -50%) scale(0.8);
    }

    @media (max-width: 700px) {
      transform: translate(-50%, -50%) scale(0.6);
    }

    @media (max-width: 500px) {
      transform: translate(-50%, -50%) scale(0.45);
    }

    .buttonNext {
      position: absolute;
      top: 53%;
      left: 53%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 0 0.5px white;
      filter: blur(10px);
      backdrop-filter: blur(50px);
    }

    &.hidden {
      opacity: 0;
      transform: translate(-50%, -30%);
    }
  }

  .hidden {
    position: absolute;
    opacity: 0;
  }

  .Video {
    position: relative;
    transition: 0.5s;
    opacity: 1;

    &.paused {
      filter: blur(3px);
    }
  }

  .originLink {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: max-content;

    .button {
      position: relative;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 1rem 3rem;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }

      .text {
        font-family: "Inter";
        font-size: clamp(14px, 1.5vw, 16px);
      }
    }
  }
}
