@font-face {
  font-family: MersadRegular;
  src: url("components/Mersad Regular.otf");
}

@font-face {
  font-family: MersadExtraBold;
  src: url("components/Mersad-ExtraBold.ttf");
}

* {
  position: relative;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

section {
  z-index: 10;
  background-color: #121212;
}

.image {
  display: flex;
  justify-content: center;

  img {
    width: 98%;
  }
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
  background-color: #121212;
}

.section {
  // margin-bottom: 2rem;
  width: 100%;
  max-width: 2200px;
  overflow: hidden;
}

.HVideoLayout {
  width: 98%;
  height: calc(95dvh - 4rem);
  margin: 4rem auto 0 auto;

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 6;
  }
}

.content {
  @media (max-width: 1280px) {
    flex-direction: column;

    .introCard {
      width: 100% !important;

      .textContent {
        width: 60% !important;
      }
    }

    :nth-child(2) {
      .textContent {
        width: 85% !important;
      }
    }

    .activeCard {
      width: 100% !important;
    }
  }
}

.advantageContent {
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 450px) {
    gap: 0;
  }
}

// .content {
//   margin-top: 6rem !important;

//   @media (max-width: 1100px) {
//     margin-top: 0 !important;
//   }
// }

.content,
.advantageContent {
  width: 98%;
  margin: 0 auto 0 auto;
  display: flex;
  gap: 10px;

  .introCard,
  .activeCard {
    background-color: #1e1e1e;
    width: 50%;
    border-radius: 15px;
    height: 320px;
    color: white;

    @media (max-width: 900px) {
      height: 200px;
    }

    @media (max-width: 450px) {
      height: 220px;
      padding-bottom: 1rem;
    }

    .contactButton {
      position: absolute;
      bottom: 5%;
      right: 5%;
      text-wrap: nowrap;
    }

    .activeButton {
      position: absolute;
      z-index: 5;
      bottom: 5%;
      right: 5%;
      color: #1e1e1e;
      font-size: 12px;
      padding: 0.6rem 1rem;
      background-color: #d3e400;
      text-transform: uppercase;
      border-radius: 6px;
      transition: 0.2s;
      cursor: pointer;
      font-family: "Inter";
    }

    .hiddenButton {
      position: absolute;
      bottom: 5%;
      right: 5%;
      font-size: 12px;
      padding: 0.6rem 1rem;
      opacity: 0;
      z-index: 10;
      text-transform: uppercase;
      border-radius: 6px;
      transition: 0.2s;
      font-family: "Inter";
      cursor: pointer;
    }

    .headerContent {
      display: flex;
      justify-content: end;
      height: 20%;
      width: 90%;
      margin: 15px auto;
    }

    .textContent {
      display: flex;
      height: 60%;
      width: 80%;
      align-items: end;
      margin: 0 auto 0 2rem;
      font-size: clamp(16px, 4vw, 22px);
      font-family: "Inter";
      opacity: 0;
      transition: 0.5s;
      transform: translateY(20px);

      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:nth-child(2) .textContent {
      span {
        color: rgba(255, 255, 255, 0.75);

        b {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .cardImage {
      position: absolute;
      z-index: 7;
      top: 50%;
      left: 39%;
      transform: translate(-50%, calc(-50% + 20px));
      opacity: 0;
      transition: 0.5s;

      @media (max-width: 900px) {
        transform: translate(-50%, calc(-40% + 20px));
      }

      &.active {
        opacity: 1;
        transform: translate(-50%, -50%);

        @media (max-width: 900px) {
          transform: translate(-50%, -40%);
        }
      }
    }

    .imgBox,
    .imgBoxSpecial {
      width: 77px;
      background-color: #2f2f2f;
      border-radius: 6px;

      @media (max-width: 900px) {
        width: 62px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 900px) {
          transform: translate(-50%, -50%) scale(0.8);
        }
      }
    }

    .imgBoxSpecial {
      img {
        top: 30%;
        left: 70%;

        @media (max-width: 900px) {
          left: 70%;
        }
      }
    }

    &:first-child {
      width: 30%;

      .textContent {
        font-weight: 500;
      }
    }
  }

  .activeCard {
    width: 25%;
  }
}

.advantageContent {
  .advantageCard {
    background-color: #1e1e1e;
    width: 25%;
    border-radius: 15px;
    height: 420px;
    color: white;
    position: relative;
    transition: 0.5s;

    @media (max-width: 1200px) {
      width: clamp(25rem, 50vw, 28rem);
    }

    @media (max-width: 450px) {
      transform: scale(0.9);
      margin-top: -2rem;
    }

    @media (max-width: 390px) {
      transform: scale(0.8);
      margin-top: -5.5rem;
    }

    .headerContent {
      display: flex;
      justify-content: end;
      height: 50%;
      width: 90%;
      margin: 15px auto;
    }

    .cardImage,
    .cardImageSpecial,
    .cardImageSpecial2 {
      position: absolute;
      z-index: 7;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        transition: 0.5s;

        @media (max-width: 1350px) {
          scale: 0.9;
        }

        @media (max-width: 1200px) {
          scale: 1;
        }
      }
    }

    .cardImageSpecial {
      top: 30%;
      left: 60%;
    }

    .cardImageSpecial2 {
      top: 35%;
      left: 50%;
    }

    .textContent {
      display: flex;
      z-index: 9;
      height: 90%;
      width: 80%;
      flex-direction: column;
      justify-content: end;
      margin: 0 auto 0 2rem;
      font-size: 22px;
      font-family: "Inter";

      h4 {
        padding: 0 0 0.5rem 0;
        font-size: clamp(28px, 2vw, 32px);
      }

      span {
        font-size: clamp(18px, 2vw, 20px);
      }
    }

    .blurBlock {
      position: absolute;
      z-index: 8;
      border-radius: 140px;
      filter: blur(20px);
      background: rgba(#1e1e1e, 0.7);
      bottom: 0;
      width: 100%;
      height: 50%;

      &::before {
        content: "";
        position: absolute;
        z-index: 8;
        background: rgba(#1e1e1e, 0.7);
        border-radius: 140px;
        backdrop-filter: blur(60px);
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:first-of-type {
      transform: translateY(200px);
      background-color: #d3e400;
      color: black;

      @media (max-width: 1200px) {
        transform: translateY(0);
      }

      @media (max-width: 450px) {
        transform: translateY(0) scale(0.9);
      }

      @media (max-width: 390px) {
        transform: translateY(0) scale(0.8);
      }

      .blurBlock {
        background: rgba(#d3e400, 0.7);

        &::before {
          background: rgba(#d3e400, 0.7);
        }
      }
    }

    &:last-of-type {
      transform: translateY(200px);
      background-color: #ededed;
      color: black;

      @media (max-width: 1200px) {
        transform: translateY(0);
      }

      @media (max-width: 450px) {
        transform: translateY(0) scale(0.9);
      }

      @media (max-width: 390px) {
        transform: translateY(0) scale(0.8);
      }

      .blurBlock {
        background: rgba(#ededed, 0.7);

        &::before {
          background: rgba(#ededed, 0.7);
        }
      }
    }

    &:last-of-type .blurBlock {
      height: 30%;
    }

    &:hover {
      box-shadow: 0px 0px 55px 0px rgba(255, 255, 255, 0.75);
    }

    &:nth-child(1) {
      animation: fly-down 4s ease-in-out infinite;

      @media (max-width: 1200px) {
        animation: none;
      }
    }

    &:nth-child(2) {
      animation: fly-up 4s ease-in-out infinite;

      @media (max-width: 1200px) {
        animation: none;
      }
    }

    &:nth-child(3) {
      animation: fly-down2 4s ease-in-out infinite;

      @media (max-width: 1200px) {
        animation: none;
      }
    }

    &:nth-child(4) {
      animation: fly-up2 4s ease-in-out infinite;

      @media (max-width: 1200px) {
        animation: none;
      }
    }
  }
}

.cardLayout {
  height: 45rem;
}

.advantageRow {
  margin-top: 15rem;
  margin-bottom: 10rem;

  @media (max-width: 1200px) {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
}

.Row {
  margin: 2rem auto 2rem auto;

  .workTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Inter";
    color: white;
    // margin-top: 10rem;
    // margin-bottom: 0.5rem;
    // gap: 3rem;

    label {
      font-size: 25px;
      color: rgba(255, 255, 255, 0.7);
    }

    span {
      width: 65%;
      font-size: clamp(30px, 4vw, 35px);
    }

    .buttonFag {
      display: flex;
      justify-content: center;
      font-family: "Inter";
      font-weight: 700;
      text-decoration: none;

      span {
        width: 100%;
        background-color: #d3e400;
        padding: 1rem 6rem 1rem 6rem;
        border-radius: 6px;
        transition: 0.2s;
        color: black;
        font-size: 23px;

        // @media (max-width: 450px) {
        //   padding: 1rem 1.5rem 1rem 1.5rem;
        // }

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 0 1px white;
        }
      }
    }

    @media (max-width: 1200px) {
      display: block;
      margin-top: -6rem;
      text-align: center;

      span {
        font-size: clamp(32px, 4vw, 45px);
      }
    }

    @media (max-width: 1200px) {
      margin-top: 4rem;
    }

    @media (max-width: 1000px) {
      margin-top: 0;
    }

    @media (max-width: 860px) {
      margin-top: 4rem;
    }
  }

  .title {
    font-family: "Inter";
    color: white;
  }

  .title,
  .centralTitle {
    width: 98%;
    margin: 8rem auto 0 auto;
    // font-size: clamp(36px, 3vw, 40px);

    span {
      font-size: clamp(50px, 3vw, 100px);
    }

    .labelTitle {
      font-size: clamp(36px, 3vw, 40px);
    }

    .advTitle {
      font-size: clamp(31px, 3vw, 55px);
      margin-bottom: 1rem;
      margin-top: -2rem;
    }

    @media (max-width: 1100px) {
      margin: 0 auto;
    }

    span {
      letter-spacing: 0.2rem;
      color: white;
      font-size: 55px;
    }
  }

  .centralTitle {
    color: white;
    margin: 10rem auto 20rem auto;
    text-align: center;
    font-family: MersadRegular;
    font-weight: 500;
    font-size: 100px;
    transform: translateY(100%);

    @media (max-width: 1250px) {
      margin: 0 auto;
      transform: translateY(0);
    }

    @media (max-width: 700px) {
      margin: 10rem auto 10rem auto;
    }

    @media (width > 1250px) {
      &.scrolling {
        transform: translateY(calc(80rem - (var(--scrollTop) * 0.5)));
        height: 25em;
        transition: 0.5s;

        span {
          font-size: calc(45rem - (var(--scrollTop) / 4.5));
          transition: 0.5s;
        }
      }

      &.fixedWord {
        height: 25em;
        transition: 0.5s;
        transform: translateY(90%);

        span {
          font-size: 7.6rem;
          transition: 0.5s;
        }
      }

      &.scrollingStop {
        transform: translateY(calc(13em - var(--TempscrollTop)));
        height: 25em;

        span {
          font-size: 7.6rem;
          transition: 0.5s;
        }
      }
    }
  }

  .centralTitleWorks {
    color: white;
    margin: 0rem auto 18rem auto;
    text-align: center;
    font-family: MersadRegular;
    font-weight: 500;
    font-size: 120px;
    transform: translateY(100%);

    @media (max-width: 1250px) {
      font-size: clamp(40px, 17vw, 50px);
      margin: 0rem auto 10rem auto;
    }
  }

  .smileRow {
    width: 98%;
    margin: 1rem auto 1rem auto;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-align: center;

    @media (max-width: 1100px) {
      // display: none;
      justify-content: end;
    }

    @media (max-width: 460px) {
      justify-content: center;
    }

    div {
      overflow: hidden;
    }

    div:last-of-type:before {
      content: "";
      z-index: 9;
      position: absolute;
      top: 2.5%;
      left: 0;
      width: 100px;
      height: 202px;
      border-radius: 0% 100% 100% 0% / 100% 50% 50% 0%;
      background-color: #121212;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    div:first-of-type::before {
      content: "";
      z-index: 9;
      position: absolute;
      top: 2.2%;
      right: 0;
      width: 102px;
      height: 202px;
      border-radius: 100% 0% 0% 100% / 50% 0% 100% 50%;
      background-color: #121212;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    &.active {
      .startWord {
        transform: translateX(0);
      }

      .endWord {
        transform: translateX(5px);
      }
    }

    h1 {
      color: #d3e400;
      letter-spacing: 0;
      font-size: clamp(100px, 17vw, 260px);
      font-family: MersadRegular;

      @media (max-width: 1260px) {
        font-size: clamp(100px, 16vw, 260px);
      }

      @media (max-width: 1100px) {
        font-size: 155px;
      }

      @media (max-width: 600px) {
        font-size: 110px;
      }

      @media (max-width: 460px) {
        font-size: 94px;
      }

      @media (max-width: 380px) {
        font-size: clamp(75px, 23vw, 94px);
      }
    }

    .startWord {
      z-index: 8;
      transition: 1100ms;
      margin-right: 8rem;
      font-family: MersadExtraBold;
      transform: translateX(400px);

      @media (max-width: 1100px) {
        margin-right: 4rem;
      }

      @media (max-width: 460px) {
        margin-right: 3rem;
      }
    }

    .endWord {
      transition: 1000ms;
      margin-left: 7rem;
      font-family: MersadExtraBold;
      transform: translateX(-880px);

      @media (max-width: 1100px) {
        margin-left: 4rem;
      }

      @media (max-width: 460px) {
        margin-left: 3rem;
      }
    }

    img {
      position: absolute;
      height: 75%;
      z-index: 10;
      transform: translate(-13.5rem, 0);
      object-fit: contain;
      filter: sepia(86%) saturate(584%) hue-rotate(8deg) brightness(95%)
        contrast(101%);

      @media (max-width: 1260px) {
        transform: translate(-10rem, 0);
      }

      @media (max-width: 1100px) {
        transform: translate(-30.5rem, 0);
      }

      @media (max-width: 860px) {
        transform: translate(-13.5rem, 0) scale(0.9);
        height: 80%;
      }

      @media (max-width: 600px) {
        transform: translate(-10.5rem, 0);
      }

      @media (max-width: 460px) {
        transform: translate(0.3rem, 0);
      }
    }
  }

  .endWordMobile {
    display: flex;
    justify-content: end;
    color: #d3e400;
    letter-spacing: 0;
    font-size: 155px;
    font-family: MersadExtraBold;
    transform: translateY(-1.6rem);
    margin-right: 1rem;

    @media (max-width: 600px) {
      font-size: 110px;
    }

    @media (max-width: 400px) {
      font-size: 94px;
      margin-right: 0;
    }

    @media (max-width: 380px) {
      transform: translateY(-1rem);
      font-size: clamp(75px, 23vw, 94px);
    }
  }

  .buttonRow {
    width: 98%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;

    @media (max-width: 1000px) {
      display: none;
    }

    article {
      width: 100%;
      display: flex;
      gap: 15px;
    }

    .button {
      text-align: center;
      width: 20%;
      font-size: clamp(16px, 2vw, 25px);
      color: white;
      white-space: nowrap;
      padding: 0.3rem 1rem;
      background-color: #121212;
      border-radius: 6px;
      border: 1px solid white;
      border-radius: 100px;
      transition: 0.2s;
      font-family: "Inter";
    }
  }

  .scrolling_text {
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    font-family: MersadRegular;
    margin: 4rem 0 4rem 0;

    .text {
      text-align: center;
      margin: 0 auto;
      animation: animate_text 60s linear infinite;
      font-family: MersadRegular;

      span {
        color: #ffffff;
        font-size: clamp(25px, 4vw, 40px);
        // font-size: 30px;
        font-weight: 300;
        margin: 0 30px;
        margin: 0 clamp(20px, 4vw, 45px);
      }
    }
  }
}

.workBlock {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  video::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .workCard {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    height: 500px;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      pointer-events: none;
      z-index: 1;
    }

    svg {
      width: 100%;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.last {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 50;
        mix-blend-mode: color-burn;
        background-color: #c0d100;
      }
    }

    .button {
      position: absolute;
      bottom: 150%;
      right: 0;
      transform: translate(-50%, -50%);
      background-color: black;
      border-radius: 6px;
      padding: 0.5rem 1rem;

      @media (max-width: 900px) {
        bottom: 0;
        right: -2%;
      }

      @media (max-width: 600px) {
        bottom: -10%;
        right: -5%;
        scale: 0.7;
      }

      img {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 10%);
        object-fit: cover;
      }
    }

    .textContentLast {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-family: "Inter";

      label {
        display: block;
        font-size: clamp(18px, 3vw, 35px);
      }
    }

    .textContent {
      position: absolute;
      bottom: 10%;
      left: 5%;
      color: white;
      font-family: "Inter";
      z-index: 2;

      @media (max-width: 900px) {
        bottom: 5%;
      }

      label {
        display: block;
        font-size: clamp(16px, 3vw, 35px);

        @media (max-width: 900px) {
          height: 4rem;
        }

        @media (max-width: 600px) {
          height: 3rem;
        }
      }

      h4 {
        padding: 0 0 1rem 0;
        font-size: clamp(22px, 4vw, 45px);

        @media (max-width: 900px) {
          padding: 0 0 0.5rem 0;
        }
      }

      .description {
        display: block;
        opacity: 0.75;
        width: 100%;
        font-size: clamp(16px, 2vw, 18px);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  height: 640px;
  width: 98%;
  margin: 6rem auto 8rem auto;
  color: white;

  @media (max-width: 900px) {
    margin: 6rem auto 4rem auto;
  }

  .infoButtons {
    width: 60%;

    @media (max-width: 900px) {
      width: 100%;
    }

    .button {
      display: block;
      margin-bottom: 0.5rem;
      border-radius: 10px;
      background-color: #1e1e1e;
      cursor: pointer;
      padding: 2rem;
      overflow: hidden;
      max-height: 115px;
      transition: 500ms;

      .buttonHeader {
        display: flex;
        width: 100%;
        font-size: clamp(18px, 4vw, 22px);
        font-family: "Inter";

        div {
          width: 52px;
          height: 52px;
          background-color: #272727;
          border-radius: 50%;
          padding: 0.6rem;
        }

        span {
          width: 90%;
          font-weight: 500;
          margin: auto auto auto 0;
        }

        img {
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: transform 500ms;
        }
      }

      .buttonDescription {
        font-family: "Inter";
        width: 80%;
        padding-top: 2rem;
        font-size: clamp(14px, 3.6vw, 22px);
        opacity: 0.75;
      }

      &.active {
        max-height: 300px;
      }
    }
  }

  .presentation {
    width: 35%;

    img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .rotated {
      transform: translate(-50%, -50%) rotate(15deg);
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
}

.adCardBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: auto;
  margin: 0 auto 10rem auto;
  color: black;
  animation: shake-top 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation-delay: 1s;
  transition: 0.5s;

  @media (max-width: 1245px) {
    margin: 0;
    scale: 0.9;
    height: 400px;
  }

  @media (max-width: 1130px) {
    scale: 0.8;
  }

  @media (max-width: 1000px) {
    scale: 0.7;
  }

  @media (max-width: 860px) {
    scale: 1.3;
  }

  @media (max-width: 860px) {
    scale: 1.3;
  }

  @media (max-width: 600px) {
    scale: 1;
  }

  @media (max-width: 400px) {
    scale: 0.9;
    height: 350px;
  }

  @media (max-width: 380px) {
    scale: 0.8;
    height: 300px;
  }

  .adCardContent {
    transition: 1s;
    // transform: translateX(-90rem);

    // @media (max-width: 860px) {
    //   transform: translate(-90rem, -6rem);
    // }

    .adCardDescription {
      width: 91.5%;
      margin-left: 3.6rem;
      display: flex;
      position: absolute;
      justify-content: space-between;
      bottom: 10%;

      &.mobile {
        width: 95%;
        margin: 0 auto 5% 10%;
        flex-direction: column;
        justify-content: center;
      }

      .text {
        width: 57%;
        font-size: clamp(16px, 2vw, 26px);
        font-family: "Inter";
        font-weight: 500;

        &.mobile {
          width: 90%;
        }
      }

      .button {
        width: 22%;
        font-size: 24px;
        color: white;
        font-family: "Inter";
        font-weight: 500;

        &.mobile {
          width: 85%;
          margin-top: 6rem;
        }

        div {
          width: 100%;
          text-align: center;
          position: absolute;
          padding: 1rem 2rem 1rem 2rem;
          bottom: 0;
          background-color: black;
          border-radius: 10px;

          .contactButton {
            text-decoration: none;
            color: white;
          }

          &:hover {
            box-shadow: 0 0 0 1px white;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &.active {
      transform: translateX(0);

      @media (max-width: 860px) {
        transform: translate(-1rem, -6rem);
      }

      @media (max-width: 750px) {
        transform: translate(-0.5rem, -6rem);
      }

      @media (max-width: 600px) {
        transform: translate(0.5rem, -6rem);
      }

      @media (max-width: 450px) {
        transform: translate(0, -6rem);
      }
    }
  }
}

.teamBlock {
  display: flex;
  justify-content: space-between;
  width: 95%;
  // height: 680px;
  margin: 3rem auto 8rem auto;
  color: white;
  font-family: "Inter";

  @media (max-width: 1200px) {
    height: 100%;
  }

  .team_card_presentation {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card_container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    padding: 1rem 1rem 5rem 0;
    gap: 10px;
    align-items: center;

    @media (max-width: 690px) {
      flex-direction: column;
      padding: 1rem;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    // scrollbar ползунок
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 6px;
      border: 3px solid transparent;
    }

    // scrollbar track
    &::-webkit-scrollbar-track {
      background-color: #999999;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none; // Убираем стрелки скроллбара
    }
  }

  .team_card {
    flex: 0 0 auto;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-family: "Inter";

    &.last {
      width: 330px;
      height: 330px;
      border-radius: 10px;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 50;
        mix-blend-mode: color-burn;
        background-color: #c0d100;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .textContentLast {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        font-family: "Inter";

        label {
          display: block;
          font-size: clamp(18px, 3vw, 35px);
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 50%;
          // background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
          pointer-events: none;
          z-index: 5;
        }
      }
    }

    .name_block {
      width: 100%;
      height: 2.5rem;
      left: 50%;
      bottom: 12%;
      transform: translate(-50%, -50%);
      position: absolute;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.8)
        );
        pointer-events: none;
        z-index: -1;
      }

      .name {
        letter-spacing: 0.02rem;
        font-size: 20px;
      }

      @media (max-width: 768px) {
        width: 100%;
        height: 2.5rem;
        left: 50%;
        bottom: 16%;
      }
    }

    .team_image {
      width: 330px;
      height: 330px;
      border-radius: 10px;
      object-fit: cover;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
        pointer-events: none;
        z-index: 5;
      }
    }

    .card_info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 10px;

      @media (max-width: 690px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .role {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
      }

      .profession {
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
      }
    }
  }

  .nav_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    top: 37%;
    transform: translateY(-50%);
    padding: 1rem;
    z-index: 100;

    @media (max-width: 690px) {
      display: none;
    }

    img {
      width: 20px;
      height: 20px;
      filter: invert(1);
    }

    &.left {
      position: absolute;
      left: 1%;
    }

    &.right {
      position: absolute;
      right: 1%;
    }
  }

  .teamDescription {
    width: 55%;

    @media (max-width: 1200px) {
      display: none;
    }

    .header {
      padding: 0 1.5rem 2rem 1.5rem;
      font-size: 60px;
      font-family: "Inter";
    }

    .textContent {
      padding: 1rem;
      font-family: "Inter";

      .profession {
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        font-size: 22px;
      }

      .name {
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        font-size: 45px;
        font-weight: 500;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 50%;
          /* Задайте необходимую высоту виньетки */
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0)
          );
          /* Темный сверху, прозрачный снизу */
          pointer-events: none;
          /* Чтобы клик не перекрывался виньеткой */
          z-index: -1;
          /* Убедитесь, что этот слой не перекрывает текст */
        }
      }

      .addInf {
        width: 75%;
        max-height: 8.8rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        font-size: 22px;
        opacity: 0.65;
      }

      &.fadeIn {
        opacity: 1;
        transition: opacity 0.5s ease-in;
      }

      &.fadeOut {
        opacity: 0;
        transition: opacity 0.5s ease-out;
      }
    }

    .button {
      position: absolute;
      display: flex;
      width: 70%;
      bottom: 20%;
      justify-content: center;
      font-family: "Inter";

      .contactButton {
        text-decoration: none;
        color: white;
      }

      span {
        background-color: #232323;
        padding: 1rem 3rem 1rem 3rem;
        border-radius: 6px;
        transition: 0.2s;

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 0 1px white;
        }
      }
    }
  }

  .teamPresentation {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    margin-right: 2rem;

    @media (max-width: 1200px) {
      width: 100%;
      margin-right: 0;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .portrait {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    width: 245px;
    height: 216px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      pointer-events: none;
      z-index: 1;
    }

    @media (max-width: 1200px) {
      width: 100%;
      height: 450px;

      .image {
        height: 100%;
        width: 100%;
      }

      .descriptionBox {
        width: 100%;
        height: 100%;
        transform: translateY(-101%);
        border-radius: 10px;
        z-index: 45;
        transition: 0.5s;

        .profession {
          position: absolute;
          top: 15%;
          left: 5%;
          font-size: clamp(15px, 4vw, 25px);
          opacity: 0;

          &.fadeIn {
            opacity: 1;
            transition: opacity 0.5s ease-in;
          }
        }

        .name {
          position: absolute;
          top: 25%;
          left: 5%;
          font-size: clamp(30px, 4vw, 45px);
          opacity: 0;

          &.fadeIn {
            opacity: 1;
            transition: opacity 0.5s ease-in;
          }
        }

        .addInf {
          position: absolute;
          width: 80%;
          top: 40%;
          left: 5%;
          font-size: clamp(15px, 4vw, 21px);
          opacity: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          line-height: 1.2;
          height: calc(1.2em * 7);

          @media (max-width: 600px) {
            width: 90%;
          }

          &.fadeIn {
            opacity: 0.65;
            transition: opacity 0.5s ease-in;
          }
        }

        .button {
          height: 10%;
          position: relative;
          top: 80%;
          opacity: 0;
          display: none;

          &.fadeIn {
            opacity: 1;
            transition: opacity 0.5s ease-in;
            display: block;
          }

          .contactButton {
            display: flex;
            justify-content: center;
            width: 80%;
            height: 100%;
            margin: 0 auto;
            color: white;
            background-color: #232323;
            border-radius: 6px;

            span {
              width: max-content;
              opacity: 1;
              font-size: 12px;
              font-family: "Inter";
              font-weight: 400;
              right: none;
              left: 50%;
              top: 55%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      @media (width >=1200px) {
        &:hover {
          z-index: 99;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            left: 47%;
            top: 12%;
            transform: translate(-50%, -50%);
            border: 1px solid white;

            @media (max-width: 600px) {
              top: 15%;
            }
          }
        }
      }

      @media (max-width: 1200px) {
        height: 100% !important;

        &.active {
          z-index: 99;

          .descriptionBox .profession,
          .descriptionBox .name {
            position: relative;
            transform: translateY(50%);
          }

          .descriptionBox .name {
            transform: translateY(-250%);
          }

          .descriptionBox .addInf {
            position: relative;
            transform: translateY(-40%);
            overflow: visible;
            -webkit-line-clamp: unset;
            height: auto;
          }

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            left: 47%;
            top: 12%;
            transform: translate(-50%, -50%);
            border: 1px solid white;

            @media (max-width: 600px) {
              top: 13%;
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      height: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &:hover {
        cursor: pointer;
      }
    }

    span {
      position: absolute;
      right: 5%;
      bottom: 5%;
      text-decoration: none;
      color: white;
      font-size: 29px;
      opacity: 0;
    }

    .fadeIn {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }

    .fadeOut {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }
}

.fagBlock {
  width: 95%;
  margin: 18rem auto 8rem auto;
  color: rgb(255, 255, 255);
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 690px) {
    margin: 10rem auto 8rem auto;
  }

  .headerFag {
    font-size: clamp(123px, 30vw, 330px);
    text-align: center;
    margin-bottom: 6rem;
    position: relative;
    font-family: MersadExtraBold;
    // color: #121212;
  }

  .light {
    position: absolute;
    z-index: 5;
    left: 48%;
    top: 13%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    mix-blend-mode: darken;

    @media (max-width: 600px) {
      transform: translate(-50%, -50%) scale(0.9);
    }

    @media (max-width: 465px) {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  .fagButtons {
    width: 80%;
    margin: 0 auto 8rem auto;
    height: 350px;
    z-index: 8;
    transition: 1s;

    &.full {
      height: 1350px;

      .button {
        &:nth-child(1n + 4) {
          opacity: 1;
        }
      }
    }

    @media (max-width: 600px) {
      width: 94%;
      margin: 0 auto 6rem auto;
    }

    .button {
      display: block;
      margin-bottom: 0.5rem;
      border-radius: 10px;
      background-color: #1e1e1e;
      overflow: hidden;
      transition: 0.5s;
      max-height: 90px;

      &:nth-child(1n + 4) {
        opacity: 0;
      }

      @media (max-width: 600px) {
        max-height: 90px;
      }

      .buttonHeader {
        display: flex;
        width: 100%;
        font-size: clamp(15px, 1.9vw, 24px);
        padding: 2rem calc(2rem + 7%) 2rem 2rem;
        font-family: "Inter";
        font-weight: 500;

        @media (max-width: 600px) {
          padding: 1.7rem calc(2rem + 9%) 1rem 1rem;
        }

        div {
          position: absolute;
          top: 20%;
          right: 5%;
          width: 50px;
          height: 50px;
          background-color: #272727;
          border-radius: 50%;
          padding: 0.6rem;

          @media (max-width: 600px) {
            scale: 0.8;
          }

          @media (max-width: 450px) {
            scale: 0.7;
          }

          .toggle {
            position: absolute;
            top: 48%;
            left: 26%;
            transform: translate(-50%, -50%);
            width: 25px;
            height: 3px;
            background-color: white;
            border-radius: 4px;
            transition: 0.5s;

            &:nth-child(1) {
              transform: rotate(-180deg);
            }

            &:nth-child(2) {
              transform: rotate(90deg);
            }
          }
        }
      }

      .buttonDescription {
        width: 80%;
        font-family: "Inter";
        padding: 2rem;
        font-size: clamp(14px, 3vw, 22px);
        opacity: 0.75;

        @media (max-width: 1000px) {
          width: 100%;
        }
      }

      &.active {
        max-height: 230px;
        color: black;
        background-color: #c0d100;

        .buttonHeader div .toggle {
          &:nth-child(1) {
            transform: translate(0, 0) rotate(0);
          }

          &:nth-child(2) {
            transform: translate(0, 0) rotate(0);
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    z-index: 99;

    .buttonFag {
      display: flex;
      justify-content: center;
      font-family: "Inter";
      font-weight: 500;
      padding: 1.5rem 0;

      .contactButton {
        text-decoration: none;
      }

      span {
        background-color: #d3e400;
        padding: 1rem 3rem 1rem 3rem;
        border-radius: 6px;
        transition: 0.2s;
        color: black;

        @media (max-width: 450px) {
          padding: 1rem 1.5rem 1rem 1.5rem;
        }

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 0 1px white;
        }
      }
    }

    .buttonFagShowMore {
      display: flex;
      justify-content: center;
      font-family: "Inter";
      font-weight: 500;
      padding: 1.5rem 0;

      span {
        background-color: #1e1e1e;
        padding: 1rem 3rem 1rem 3rem;
        border-radius: 6px;
        transition: 0.2s;
        color: rgb(255, 255, 255);

        @media (max-width: 450px) {
          padding: 1.2rem 1.5rem 1.2rem 1.5rem;
        }

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 0 1px white;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    transform: translateY(-25%);
    z-index: 7;
    width: 100%;
    height: 100%;
    background-color: #121212;
    mix-blend-mode: lighten;
  }
}

.reviewBlock {
  margin-bottom: 3rem;

  .centralTitle {
    width: 95%;
    margin: 14rem auto 0 auto;
    text-align: center;
    color: white;

    @media (max-width: 768px) {
      margin: 9rem auto 0 auto;
    }

    h3 {
      font-size: clamp(32px, 5.5vw, 50px);
      font-family: "Inter";
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .headerSpan {
      font-size: clamp(18px, 4vw, 30px);
      color: rgba(255, 255, 255, 0.7);
      font-family: "Inter";
      // margin-bottom: 1rem;
    }

    .description {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      color: rgba(255, 255, 255, 0.7);
      font-family: "Inter";
      font-weight: 300;

      span {
        display: block;
        font-size: clamp(12px, 3.4vw, 20px);
        width: 35rem;
        color: rgba(255, 255, 255, 0.7);

        text {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .reviewCardsCarousel {
    display: flex;
    margin: 6rem 0 40rem 0.5rem;

    @media (max-width: 768px) {
      margin: 2rem 0 27rem 0.5rem;
    }

    .cardsContainer {
      display: flex;
    }

    .card {
      position: absolute;
      width: 480px;
      // height: 360px;
      background-color: #1e1e1e;
      color: white;
      border-radius: 10px;
      padding: 20px;
      text-align: left;
      transition: 0.5s;

      @media (max-width: 520px) {
        width: 380px;
      }

      @media (max-width: 400px) {
        transform: translateX(-50%) scale(0.9) !important;
      }

      .reviewCardHeader {
        .like {
          position: absolute;
          right: 0;
          top: 0;
          padding: 0.5rem;
          transition: 0.5s;
          border-radius: 10px;
          background-color: #2a2a2a;
        }

        .name {
          width: 85%;
          margin: 0 auto;
          padding: 1.5rem 0 0 0;
          font-family: "Inter";
          font-weight: 500;

          span {
            font-size: clamp(18px, 5vw, 30px);
          }
        }

        // Обновленные стили для .position
        .position {
          width: 85%;
          margin: 0 auto;
          padding: 0.5rem 0 1.5rem 0;
          font-family: "Inter";
          font-weight: 400;
          color: #9e9e9e;
          border-bottom: 1px solid rgb(233, 233, 233, 1);

          span {
            font-size: clamp(14px, 4vw, 18px);
          }
        }
      }

      .reviewDescriptionText {
        width: 85%;
        margin: 0 auto;
        transition: 0.5s;
        font-size: clamp(12px, 2.4vw, 15px);
        line-height: 23px;

        padding: 2rem 0 2rem 0;
        font-family: "Inter";

        p {
          margin-bottom: 0.2rem;
        }
      }

      &.card1 {
        left: 0;
        transform: translateX(-50%) scale(0.6);
        opacity: 0;
      }

      &.card2 {
        left: 18%;
        z-index: 8;
        transform: translateX(-50%) scale(0.8);
        opacity: 0.75;
        color: rgba(255, 255, 255, 0.8);

        @media (max-width: 1100px) {
          opacity: 0;
        }
      }

      &.card3 {
        left: 50%;
        transform: translateX(-50%) scale(1);
        z-index: 9;
        opacity: 1;
        color: rgba(255, 255, 255, 0.8);
      }

      &.card4 {
        left: 82%;
        transform: translateX(-50%) scale(0.8);
        z-index: 8;
        opacity: 0.75;
        color: rgba(255, 255, 255, 0.8);

        @media (max-width: 1100px) {
          opacity: 0;
        }
      }

      &.card5 {
        left: 100%;
        transform: translateX(-50%) scale(0.6);
        opacity: 0;
      }

      &.hidden {
        display: none;
      }

      &:hover {
        opacity: 1;
        color: black;
        background-color: #c0d100;

        // .name {
        //   border-color: black;
        // }

        // .like {
        //   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
        //     brightness(100%) contrast(104%);
        // }

        .position {
          color: #000000;
          /* Цвет текста при hover */
          border-bottom-color: #000000;
          /* Цвет нижней границы при hover */
          transition: all 0.6s ease;
        }

        .reviewDescriptionText {
          color: black;
        }
      }
    }
  }

  .reviewNav {
    position: absolute;
    top: 30rem;
    // margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 1100px) {
      top: 22rem;
    }

    button {
      background-color: #2a2a2a;
      width: 55px;
      height: 55px;
      color: white;
      border: none;
      border-radius: 50%;
      margin: 0 10px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: invert(100%) sepia(0%) saturate(196%) hue-rotate(125deg)
          brightness(112%) contrast(100%);
      }

      &:nth-child(1) img {
        left: 45%;
      }

      &:nth-child(2) img {
        left: 53%;
      }

      &:hover {
        background-color: #ddd;

        img {
          filter: sepia(100%) saturate(0%) hue-rotate(255deg) brightness(100%)
            contrast(104%);
        }
      }
    }
  }
}

.contactBlock {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid white;
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 768px) {
    transform: translateY(2rem);
  }

  @media (max-width: 1000px) {
    border-top: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  &::after {
    top: auto;
    bottom: 5%;
    left: 0;
    width: 1px;
    height: 95%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4),
      rgba(0, 0, 0, 0.2)
    );
  }

  &::before {
    left: auto;
    bottom: 5%;
    right: 0;
    width: 1px;
    height: 95%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4),
      rgba(0, 0, 0, 0.2)
    );
  }

  .container {
    position: relative;

    &__contact {
      padding-top: 0.5em;

      @media (max-width: 1000px) {
        text-align: center;

        h1 {
          font-size: 28px;
        }
      }

      h1 {
        width: 90%;
        margin: 3rem auto 0 auto;
        font-family: "Inter";
        font-weight: 500;
        color: white;
        font-size: 50px;

        @media (max-width: 1000px) {
          margin: 4rem auto 0 auto;
        }
      }
    }
  }

  .cardGridMobile {
    width: 100%;
    margin: 0 auto;
    margin-top: 4em;
    padding-bottom: 2.5em;
    border-top: 1px solid white;
    border-radius: 35px;

    &::before,
    &::after {
      content: "";
      position: absolute;
    }

    &::after {
      top: auto;
      bottom: 1.5%;
      left: 0;
      width: 1px;
      height: 95%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2),
        rgba(0, 0, 0, 0.2)
      );
    }

    &::before {
      left: auto;
      bottom: 1.5%;
      right: 0;
      width: 1px;
      height: 95%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2),
        rgba(0, 0, 0, 0.2)
      );
    }

    .socialContainerMobile {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 0.2em 0.2em 4rem 0.2em;
      width: 100%;
      height: 950px;

      @media (max-width: 660px) {
        height: 800px;
      }

      @media (max-width: 540px) {
        height: 700px;
      }

      @media (max-width: 440px) {
        height: 650px;
      }

      :nth-child(5) {
        width: 100% !important;
      }

      .socialCard {
        position: relative;
        width: 49%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        margin: 0.5%;
        border-radius: 35px;
        transition: 0.15s;
        transition: background-color 0.6s;

        img,
        .title {
          transform: scale(0.8);
          transition: transform 0.7s;

          @media (max-width: 660px) {
            transform: scale(0.7);
          }

          @media (max-width: 440px) {
            transform: scale(0.6);
          }
        }

        &:hover {
          cursor: pointer;
          background-color: white;

          img {
            transform: scale(1);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
              brightness(100%) contrast(104%);
          }

          .title {
            transform: translate(-50%, -100%);
            color: black;
          }
        }

        .image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .title {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
          font-size: clamp(10px, 2vw, 20px);
          font-weight: 800;
          bottom: 10%;
          color: white;
          font-family: "Inter";
        }
      }
    }
  }

  .cardGrid {
    width: 100%;
    margin: 0 auto;
    margin-top: 7em;
    padding-bottom: 2.5em;

    .socialContainer {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      max-width: 95%;
      margin: 0 auto;
      gap: 40px;
      margin-bottom: 10rem;

      .socialCard {
        position: relative;
        width: 240px;
        height: 240px;
        border-radius: 30%;
        transition: 0.15s;
        transition: background-color 0.6s;

        img {
          transform: scale(0.8);
          transition: transform 0.7s;
        }

        &:hover {
          cursor: pointer;
          background-color: #ffffff;

          img {
            transform: scale(1);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
              brightness(100%) contrast(104%);
          }
        }

        .image {
          position: absolute;
          margin-top: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      .title {
        display: block;
        text-align: center;
        color: white;
        font-size: 15px;
        margin-top: 2rem;
        text-transform: uppercase;
        font-family: MersadExtraBold;
      }
    }
  }

  .sideMail {
    width: 99%;
    margin: 0 auto;

    .socialCard {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: #1e1e1e;

      .mailBlank {
        width: 60%;
        margin-left: 4rem;

        .mailContainer {
          margin: 4rem 0rem 3rem 0rem;

          .mailTile {
            text-transform: uppercase;
            font-size: 22px;
            margin: 0rem 0 1rem 0;
            font-family: "Inter";
            font-weight: 500;
            color: #c0d100;

            @media (max-width: 768px) {
              font-size: 18px;
            }
          }

          .mailInf {
            font-size: 16px;
            font-family: "Inter";
            margin-bottom: 2rem;

            a {
            }
          }
        }
        .title {
          width: 60%;
          text-transform: uppercase;
          font-size: 21px;
          margin: 0 0 5rem 0;
          font-family: "Inter";
          color: white;

          @media (max-width: 768px) {
            width: 80%;
            font-weight: 400;
            font-size: 18px;
          }
        }

        .title a {
          color: #c0d100;
          /* Цвет ссылки */
          text-decoration: none;
          /* Убираем подчеркивание */
        }

        @media (max-width: 768px) {
          margin-left: 1.5rem;
        }
      }

      @media (max-width: 768px) {
        .mailBlank {
          width: 100%;
        }
      }

      .fieldBox {
        width: 40%;
        height: 80%;
        margin: 2rem 5rem 0 auto;
        display: flex;
        flex-wrap: wrap;

        :nth-child(5) {
          width: 100% !important;
        }

        .fieldElem:nth-child(1),
        .fieldElem:nth-child(3) {
          margin-right: 2%;
        }

        .fieldElem {
          width: 49%;
          margin-bottom: 0.5rem;

          .image {
            position: absolute;
            top: 7%;
            right: 2%;
            padding: 0.2rem;
            border-radius: 8px;
            cursor: pointer;
            transform: translate(-50%, -50);
            width: 33px;
            height: 30px;
            background-color: #313131;
          }

          .inputField {
            min-width: 100%;
            max-width: 100%;
            min-height: 2.6rem;
            max-height: 120px;
            color: rgba(255, 255, 255, 0.7);
            background-color: #1e1e1e;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 15px;
            padding-left: 15px;
            font-size: 15px;
            font-family: "Inter";
          }

          .textArea {
            min-width: 100%;
            max-width: 100%;
            min-height: 4.2rem;
            max-height: 120px;
            padding: 10px 2.4rem 0 10px;
            color: rgba(255, 255, 255, 0.7);
            background-color: #1e1e1e;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 15px;
            padding-left: 15px;
            font-size: 15px;
            margin-bottom: 3rem;
            font-family: "Inter";
            resize: vertical;

            &:focus {
              border: 1px solid rgba(255, 255, 255, 0.6);
            }
          }
        }

        .button {
          width: 100%;
          display: flex;
          justify-content: center;
          color: rgb(255, 255, 255);
          // background-color: rgba(#ffffff, 0.8);
          padding: 0.5rem;
          border-radius: 12px;
          box-shadow: 0 0 0 1px rgb(255, 255, 255);
          font-family: "Inter";
          cursor: pointer;
          margin-bottom: 2rem;

          &:hover {
            background-color: rgba(#c0d100, 0.9);
            color: black;
            box-shadow: 0 0 0 1px #c0d100;
          }
        }

        .selectedFiles {
          display: flex;
          position: absolute;
          transform: translateY(-140%);
          width: 100%;
          color: rgba(255, 255, 255, 0.75);

          .fileName {
            width: 20%;
            display: flex;
            margin-right: 0.5rem;
            border-radius: 15px;
            box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.75);
            background-color: #1e1e1e;

            label {
              width: 90%;
              padding: 0.3rem 0.3rem 0.3rem 0.8rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 14px;
              font-family: "Inter";
            }

            b {
              display: flex;
              align-items: center;
              color: black;
              background-color: red;
              cursor: pointer;
            }
          }

          .cancelButton {
            position: absolute;
            right: 20%;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            .cancel {
              position: absolute;
              top: 50%;
              left: 14%;
              transform: translate(-50%, -50%);
              width: 16px;
              height: 1.2px;
              background-color: rgb(255, 255, 255);
              border-radius: 4px;
              transition: 0.5s;

              &:nth-child(1) {
                transform: rotate(-135deg);
              }

              &:nth-child(2) {
                transform: rotate(135deg);
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        .fieldBox {
          display: block;
          width: 90%;
          height: 80%;
          margin: 0;
          flex-wrap: wrap;
          margin-left: 5%;

          .fieldElem {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .socialCard {
        display: block;
        /* Убираем flex на мобильных устройствах */
      }
    }
  }
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@keyframes animate_text {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fly-up {
  0% {
    transform: translateY(80px);
  }

  50% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(80px);
  }
}

@keyframes fly-down {
  0% {
    transform: translateY(-160px);
  }

  50% {
    transform: translateY(-130px);
  }

  100% {
    transform: translateY(-160px);
  }
}

@keyframes fly-up2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fly-down2 {
  0% {
    transform: translateY(-70px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(-70px);
  }
}

@keyframes shake-top {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0;
  }

  10% {
    transform: rotate(0deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-2deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(2deg);
  }

  80% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(0deg);
  }
}
