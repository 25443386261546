.headerVideo {
  width: 98%;
  height: calc(95dvh - 4rem);
  margin: 4rem auto 0 auto;

  @media (max-width: 800px) {
    height: calc(40dvh - 4rem);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 6;

    &.notFullScreened {
      &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
      }
    }
  }

  .playButton {
    position: absolute;
    transition: 0.5s;
    width: 100px;
    height: 100px;
    background-color: rgba(#fff, 0.05);
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 0.5px white;
    filter: blur(0.5px);
    backdrop-filter: blur(15px);
    opacity: 1;

    @media (max-width: 800px) {
      opacity: 0;
    }

    .buttonNext {
      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        filter: invert(100%) sepia(0%) saturate(196%) hue-rotate(125deg)
          brightness(112%) contrast(100%);
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 0 0.5px white;
      filter: blur(10px);
      backdrop-filter: blur(50px);
    }

    &.hidden {
      opacity: 0;
      transform: translate(-50%, -30%);
    }

    &:hover.hidden {
      opacity: 1;
      transform: translate(-50%, -50%);

      @media (max-width: 800px) {
        opacity: 0;
      }
    }

    @media (max-width: 800px) {
      opacity: 0;
    }
  }

  .hidden {
    position: absolute;
    opacity: 0;
  }

  .Video {
    position: relative;
    transition: 0.5s;
    opacity: 1;
    object-fit: contain;

    &.paused {
      filter: blur(3px);
    }
  }
}

.video_controls {
  position: absolute;
  z-index: 8;
  bottom: 5%;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;

  .progress_container {
    width: 95%;
    margin: 0 auto;
    background: #333;
    height: 2px;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.3s;
  }

  .video_title {
    color: #fff;
    width: 95%;
    margin: 0 auto;
    font-size: 25px;
    margin-bottom: 1rem;
    font-family: "Inter";
  }

  &.hidden {
    display: none;
  }

  &.active {
    .progress_container {
      &:hover {
        cursor: pointer;
        height: 4px;
      }
    }
  }
}

.progress_bar {
  width: 0;
  height: 100%;
  background: #fff;
  transition: width 0.1s;
}

.video_header {
  display: flex;
  justify-content: space-between;

  .video_fullscreen {
    position: absolute;
    right: 0;
    top: -20px;
    z-index: 8;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
      brightness(100%) contrast(104%);
    cursor: pointer;
  }

  .mute_button {
    position: absolute;
    right: 40px;
    transform: scale(1.3);
    top: -20px;
    z-index: 8;
    cursor: pointer;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
      brightness(100%) contrast(104%);
  }
}
