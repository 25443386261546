@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.Headerlayout {
  @include noselect();
  position: fixed;
  width: 100%;
  max-width: 2200px;
  margin: 0 auto;
  z-index: 12;
  transition: 0.4s;
  background-color: transparent;
  //   text-wrap: nowrap;
  //   overflow: hidden;
  color: white;
  text-transform: uppercase;

 

  &.active {
    .headerContent .buttonSide .h_but_s {
      opacity: 1;
    }
  }

  .headerContent {
    display: flex;
    width: 100%;
    max-width: 2200px;
    position: fixed;
    margin: 0 auto;
    z-index: 999;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 120%; /* Задайте необходимую высоту виньетки */
      background: linear-gradient(to bottom, rgba(11, 11, 11, 1), rgba(0, 0, 0, 0)); /* Темный сверху, прозрачный снизу */
      pointer-events: none; /* Чтобы клик не перекрывался виньеткой */
      z-index: 0; /* Убедитесь, что этот слой не перекрывает текст */
  }

    .menuButton {
      position: absolute;
      right: 2%;
      width: 50px;
      height: 50px;
      z-index: -1;
      opacity: 0;
      transition: 0.5s;

      .toggle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 3px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        transition: 0.5s;
        margin-top: 0.5rem;

        &:nth-child(1) {
          top: 35%;
        }

        &:nth-child(2) {
          top: 50%;
        }

        &:nth-child(3) {
          width: 15px;
          left: calc(50% - 5px);
          top: 65%;
        }
      }

      &.visible {
        opacity: 1;
        z-index: 999;
      }

      &.active {
        transform: translateX(-25%);
        .toggle {
          &:nth-child(1) {
            top: 50%;
            transform: rotate(135deg);
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translate(0, 0) rotate(0);
          }

          &:nth-child(3) {
            top: 50%;
            left: 50%;
            width: 25px;
            transform: rotate(-135deg);
          }
        }
      }
    }

    .titleSide {
      flex: 1 1;
      margin-top: 0.5rem;
      padding: 0.6rem 0.7rem 0.5rem 1rem;
      font-size: 16px;
      font-family: "Inter";
      font-weight: 800;
      color: white;
      z-index: 99;

      span {
        cursor: pointer;
      }

      @media (max-width: 690px) {
        position: absolute;
        padding: 1rem 0.7rem 0.5rem 1rem;
      }
    }
    .h_but_s_Mobile {
      position: absolute;
      right: 4rem;
      margin-top: 1rem;
      padding: 0.6rem 0.7rem 0.5rem 1rem;
      text-decoration: none;
      font-family: "Inter";
      background-color: #d3e400;
      color: #121212;
      font-weight: 500;
      border-radius: 6px;
      font-size: 12px;
    }
    .buttonSide {
      margin-top: 0.5rem;
      &.mobile {
        width: 100%;
        height: 0;
        border-radius: 0 0 15px 15px;
        top: 23.5%;
        display: flex;
        justify-content: center;
        box-shadow: none;
        transition: 1s;
        margin-top: 0;
        overflow: hidden;

        ul {
          width: 90%;
          margin-top: 0 !important;
          flex-direction: column;
          align-items: center;
          opacity: 0;
          height: max-content;
          transition: 1s;

          li {
            width: 100%;
          }

          .h_but_s {
            font-family: "Inter";
            background-color: transparent;
            color: white;
            font-weight: 500;
            border-radius: 0;
            font-size: 12px;
            opacity: 1;
            border-bottom: 1px solid white;

            &:last-child {
              background-color: transparent;
              color: white;
              opacity: 1;
              font-weight: 500;
            }
          }

          .underline {
            width: 65%;
            height: 20%;
          }
        }

        &.active {
          backdrop-filter: blur(50px);
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          height: 30rem;
          box-shadow: 0 4px 7px 1px rgb(0, 0, 0);

          ul {
            margin-top: 10rem !important;
            opacity: 1;
          }
        }
      }

      ul {
        list-style: none;
        display: flex;
        justify-content: end;
        gap: 0.4rem;
        padding-right: 1rem;
        padding-bottom: 0.7rem;
        color: white;

        .h_but_s {
          font-size: 12px;
          padding: 0.6rem 1rem;
          background-color: #232323;
          border-radius: 6px;
          transition: 0.2s;
          cursor: pointer;
          opacity: 1;
          font-family: "Inter";
          text-decoration: none;

          &:last-child {
            background-color: #d3e400;
            color: #121212;
            cursor: pointer;
            opacity: 1;
            font-weight: 500;
          }

          &:hover {
            box-shadow: 0 0 0 1px white;
          }
        }
      }
    }
  }
}
